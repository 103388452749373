var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{class:["columns"]},[_c('div',{class:["column"]},[_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Title:")]),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"Title"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Description:")]),_c('b-input',{attrs:{"placeholder":"Description","type":"textarea"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Short Description:")]),_c('b-input',{attrs:{"placeholder":"Short Desciption","maxlength":"200","type":"textarea"},model:{value:(_vm.formData.shortdescription),callback:function ($$v) {_vm.$set(_vm.formData, "shortdescription", $$v)},expression:"formData.shortdescription"}})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Total Available Count:")]),_c('ValidationProvider',{attrs:{"name":"Total Available Count","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-numberinput',{attrs:{"placeholder":"Total Available Count","controls-alignment":"right"},model:{value:(_vm.formData.totalavailablecount),callback:function ($$v) {_vm.$set(_vm.formData, "totalavailablecount", $$v)},expression:"formData.totalavailablecount"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Point to Redeem:")]),_c('b-numberinput',{attrs:{"placeholder":"Point to Redeem","controls-alignment":"right"},model:{value:(_vm.formData.redeempoint),callback:function ($$v) {_vm.$set(_vm.formData, "redeempoint", $$v)},expression:"formData.redeempoint"}})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Sort Value:")]),_c('ValidationProvider',{attrs:{"name":"Sort Value","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-numberinput',{attrs:{"placeholder":"Sort Value","controls-alignment":"right"},model:{value:(_vm.formData.sortvalue),callback:function ($$v) {_vm.$set(_vm.formData, "sortvalue", $$v)},expression:"formData.sortvalue"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('b-field',{attrs:{"label":"Redeem Method"}},[_c('ValidationProvider',{attrs:{"name":"Redeem Method","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"placeholder":"Select a method"},model:{value:(_vm.formData.redeemmethodtype),callback:function ($$v) {_vm.$set(_vm.formData, "redeemmethodtype", $$v)},expression:"formData.redeemmethodtype"}},_vm._l((_vm.redeemMethodTypes),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.formData.redeemmethodtype)?[(_vm.isVisualRedeemMethod)?_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Redeem Url:")]),_c('ValidationProvider',{attrs:{"name":"Redeem URL","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"Redeem URL"},model:{value:(_vm.formData.redeemurl),callback:function ($$v) {_vm.$set(_vm.formData, "redeemurl", $$v)},expression:"formData.redeemurl"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Redeem Code:")]),_c('ValidationProvider',{attrs:{"name":"Redeem Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"Redeem Code"},model:{value:(_vm.formData.redeemcode),callback:function ($$v) {_vm.$set(_vm.formData, "redeemcode", $$v)},expression:"formData.redeemcode"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_vm._e(),_c('div',{class:[_vm.$style.inputContainer]},[_c('b-switch',{model:{value:(_vm.formData.isactive),callback:function ($$v) {_vm.$set(_vm.formData, "isactive", $$v)},expression:"formData.isactive"}},[_vm._v(" "+_vm._s(_vm.formData.isactive ? "Enabled" : "Disabled")+" ")])],1)],2),_c('div',{class:["column"]},[_c('div',{class:_vm.$style.inputContainer},[_c('h5',{staticClass:"label"},[_vm._v("Thumbnail (5MB max)")]),_c('Upload',{class:_vm.$style.uploadContainer,attrs:{"fileTypes":_vm.imageFileTypes,"previousSelectedFile":_vm.formData.thumbnailurl,"showDeleteButton":true},on:{"input":_vm.onThumbnailUpload}})],1),_c('div',{class:_vm.$style.inputContainer},[_c('h5',{staticClass:"label"},[_vm._v("Banner (5MB max)")]),_c('Upload',{class:_vm.$style.uploadContainer,attrs:{"fileTypes":_vm.imageFileTypes,"previousSelectedFile":_vm.formData.bannerurl,"showDeleteButton":true},on:{"input":_vm.onBannerUpload}})],1)])]),_c('div',{class:_vm.$style.buttonContainer},[_c('input',{attrs:{"type":"submit","disabled":invalid},domProps:{"value":_vm.isEdit ? 'Update' : 'Add'}})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }