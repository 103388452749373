








import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { RewardStores } from '@/store/rewards';
import RewardForm from '@/components/rewards/RewardForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    RewardForm,
  },
})
export default class AddRewards extends Vue {
  rewardStore = RewardStores;

  unsubscribe: () => void = () => {};

  handleSubmit(payload: any) {
    this.rewardStore.createReward(payload);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'reward/CREATE_REWARD_SUCCESS') {
        this.$router.replace('/rewards');
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
